<template>
  <div ref="body">
    <custom-header active="service" :subActive="current"></custom-header>
    <!-- <div class="banner-container">
      <van-swipe ref="banner" :autoplay="5000" class="fill">
        <van-swipe-item v-for="(item, idx) in banners" :key="idx">
          <div class="fill" @click="urlLink(item.url)">
            <img :src="item.bannerPicture" alt="" style="width: 100%; height: auto;">
          </div>
        </van-swipe-item>
      </van-swipe>
    </div> -->
    <div class="content-container">
      <h1 class="text-bold">媒体中心</h1>
      <div class="content1">
        <div class="exh-photo">
          <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/media.jpg" class="block fill" />
        </div>
        <div class="intro">
          <div class="intro1">
            <p class="text-bold">各位媒体朋友，您好！</p>
            <p class="text-bold">感谢您对深圳家居设计周暨</p>
            <p class="text-bold">深圳国际家具展的关注！</p>
          </div>
          <div class="intro1-1">
            <p class="text-bold">各位媒体朋友，您好！</p>
            <p class="text-bold">感谢您对深圳家居设计周暨深圳国际家具展的关注！</p>
          </div>
          <div class="intro2">
            <p class="">在此您可以获取展会相关资料，如您有意向成为我们的合作媒体，欢迎联系：</p>
          </div>
          <div class="contacts">陈女士 18620388039</div>
        </div>
      </div>
      
      <div class="media-bar">
        <div class="media-item relative" style="background-color: #333333;">
          <div class="title">新闻稿</div>
          <a href="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/%E5%AE%98%E5%AE%A3%20_%202024%E5%B9%B4%E5%BA%A6%E4%B8%BB%E9%A2%98%EF%BC%9A%E4%B8%AD%E5%9B%BD%E6%96%B0%E5%B1%85.pdf" download="官宣_2025年度主题：中国新居.pdf" target="_blank"><img src="../assets/img/download.png" alt="深圳家居设计周" ></a>
        </div>
        <div class="media-item relative" style="background-color: #00ABA5;">
          <div class="title">展会视频/照片</div>
          <a @click="toPicture"><img src="../assets/img/more.png" alt="展会视频/照片"></a>
        </div>
        <div class="media-item relative" style="background-color: #E4007F;">
          <div class="title">展会视觉</div>
          <a href="https://exhoss39.szcreativeweek.com/applet/img/website-logo.png" download="商标logo.png" target="_blank"><img src="../assets/img/icon-logo.png" alt="商标logo" ></a>
          <a href="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/%E4%B8%BB%E8%A7%86%E8%A7%89.png" download="主视觉形象.jpg" target="_blank"><img src="../assets/img/xingxiang.png" alt="主视觉形象" ></a>
        </div>
      </div>
      
    </div>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
    
  </div>
</template>

<script>
import { Swipe, SwipeItem, Icon } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';

export default {
  // name: '媒体中心',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter, 'scroll-top': scrollTop,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  mixins: [commonMixin],
  metaInfo: {
    // title: 'My Example App', // set a title
    meta: [
      { name: 'keyWords', content: '深圳家具展，家具展，家居设计，家具展商，家居论坛，设计周，家居设计周' },
      { name: 'description', content: '深圳家居设计周暨40届深圳国际家具展，将于2025年3月16-19日在深圳国际会展中心盛大启幕，是目前国内唯一横向打通住宅、建筑、室内、家具、软装、灯光及工作环境全产业链的产业创新与教育分享展。' },
    ],
  },
  data() {
    return {
      current: 'media',
    }
  },
  created() {

  },
  methods: {
    toPicture() {
      this.$router.push('/picture')
    },
  },
}
</script>

<style scoped lang="less">
// 移动端
@media screen and (max-width: 1023px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  
  .content-container {
    width: 350px;
    margin: 0 auto;
    
    h1 {
      font-size: 18px;
      text-align: center;
      margin: 10px 0 20px;
    }
    
    .content1 {
      width: 100%;
      height: 370px;
    }
    
    .intro {
      width: 100%;
      line-height: 6.5px;
      padding-top: 20px;
      
      .intro1 {
        display: none;
      }
      .intro1-1 {
        font-size: 15px;
        margin-bottom: 5px;
        line-height: 1.5em;
      }
      
      .intro2 {
        font-size: 14px;
        padding: 10px 0;
        line-height: 1.5em;
        margin-bottom: 5px;
      }
      
      .contacts {
        font-size: 14px;
        margin-bottom: 3px;
      }
    }
    
    .exh-photo {
      width: 100%;
      // height: auto;
      color: #fff;
      line-height: 80px;
      text-align: center;
      font-size: 6px;
      
      img {
        width: 100%;
        height: auto;
        border-radius: 4px;
      }
    }
    
    .media-bar {
      margin: 20px auto 40px;
      
      .media-item {
        width: 100%;
        height: 180px;
        border-radius: 1px;
        padding: 10px;
        margin-bottom: 12px;
        border-radius: 4px;
        
        .title {
          font-size: 20px;
          font-family: 'SourceHanSansCN-Bold';
          color: #fff;
          margin-bottom: 55px;
        }
        img {
          display: block;
          width: 100%;
          height: auto;
          margin-bottom: 5px;
        }
        
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 280px;
    margin: 20px auto ;
    
    h1 {
      display: none;
    }
    
    .content1 {
      width: 100%;
      height: 100px;
    }
    .intro {
      width: 90px;
      font-size: 4px;
      line-height: 6.5px;
      float: left;
      padding-top: 10px;
      
      .intro1-1 {
        display: none;
      }
      .intro1 {
        font-size: 5px;
        margin-bottom: 5px;
        line-height: 1.5em;
      }
      
      .intro2 {
        font-size: 4px;
        
        p {
          margin-bottom: 5px;
        }
      }
      
      .contacts {
        margin-bottom: 3px;
      }
    }
    
    .exh-photo {
      width: 160px;
      color: #fff;
      line-height: 80px;
      text-align: center;
      font-size: 6px;
      float: right;
      
      
      img {
        width: 100%;
        height: auto;
        border-radius: 1px;
      }
    }
    
    .media-bar {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      
      .media-item {
        width: 80px;
        height: 50px;
        border-radius: 1px;
        padding: 10px;
        
        .title {
          font-size: 6px;
          font-family: 'SourceHanSansCN-Bold';
          color: #fff;
          margin-bottom: 10px;
        }
        img {
          display: block;
          width: 100%;
          height: auto;
          margin-bottom: 5px;
        }
        
      }
    }
  }
}
  
  
</style>